<template>
  <div>
    <div class="row">
      <div class="col-md-6">
        <h1>{{item.title}}</h1>
      </div>
      <v-spacer></v-spacer>
      <div class="col-md-6">
        <div class="text-right">
          <v-btn class="btn btn-primary" @click="$router.push({path: '/dashboard'})">Back to home</v-btn>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <iframe
            :title="item.title"
            width="100%" height="1000vh"
            :src="item.link"
            frameborder="0"
            allowFullScreen="true">
        </iframe>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";
import {GET_DASHBOARD_DETAILS} from "@/core/services/store/dashboard.module";

export default {
  name: 'module-details',
  data() {
    return {
      item: {},
    }
  },
  watch: {
    '$route': {
      handler() {
        this.getDetails()
      },
      deep: true
    },
    // dialog(val) {
    //   !val && this.$store.dispatch(CLEAR_REQUIREMENT_ERRORS);
    //   if (!val) this.editedItem = {};
    //   if (!val) bus.$emit("moduleFilesDeletedByModal");
    //   if (!val) this.activeTab = 0;
    // }
  },
  mounted() {
    // for (var i = 0; i < this.headers.length; i++) {
    //   this.columns.push(
    //       '&columns[' + i + '][data]=' + this.headers[i].data +
    //       '&columns[' + i + '][name]=' + this.headers[i].name +
    //       '&columns[' + i + '][searchable]=' + this.headers[i].filterable +
    //       '&columns[' + i + '][orderable]=' + this.headers[i].sortable +
    //       '&columns[' + i + '][search][value]=' +
    //       '&columns[' + i + '][search][regex]=' + false
    //   );
    // }
    this.getDetails();
  },
  methods: {
    refreshData(loadDep = true) {
      // this.loadData().then(response => {
      //   this.items = response.items;
      //   this.totalRecords = response.total;
      //   if (loadDep) this.getTeachers();
      // });
    },
    getDetails() {
      this.item = {}
      this.$store.dispatch(GET_DASHBOARD_DETAILS, this.$route.params.id).then((data) => {
        this.item = data.records || {};
      });
    },
  },
  computed: {
    ...mapState({
      errors: state => state.dashboard.errors
    })
  }
};
</script>